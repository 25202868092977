import React from 'react';
import classNames from 'classnames';

export default class Page extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      '': true
    });
  }

  render = () => (
    <div class="ball">
      <h1>Wordy<span>.</span></h1>
      <h2>ridiculously easy chat</h2>
    </div>
  )
}